.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #fff;
    font-family: Arial, sans-serif;
    padding: 15px;
  }
  
  .user-info {
    text-align: center;
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .username {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .gems {
    font-size: 36px;
    margin: 10px 0;
  }
  
  .tasks-container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .error-message {
    font-size: 18px;
  }
  
  .loading-message {
    font-size: 18px;
  }
  
  .more-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
 
  .more-button:hover {
    background-color: #3700b3;
  }


