body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background-color: #000;
    color: #fff;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  * {
    box-sizing: border-box;
  }